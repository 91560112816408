import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import Login from "./layouts/Login";
import RouterHOC from "./hocs/RouterHOC";
import Profile from "./layouts/Profile";

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
	{
		path: '/',
		element: <RouterHOC component={App}/>,
	},
	{
		path: '/profile',
		element: <RouterHOC component={Profile}/>
	},
	{
		path: '/login',
		element: <RouterHOC component={Login} permitted={false}/>
	},
]);

root.render(
	<React.StrictMode>
		<RouterProvider router={router}/>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();