import React from 'react';
import {Avatar, Card, Form, Input} from "antd";

const Index = (props) => {
	return (
		<Card title={'Ваш аккаунт'} style={{marginTop: '5vh', height: '75vh', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'}}>
			<center>
				<Avatar src={props.user?.logo} shape={'square'}
						style={{width: 100, height: 100}}>
					{props.user?.username}
				</Avatar>
			</center>
			<Form layout={'vertical'}>
				<Form.Item label={'Имя'}>
					<Input disabled value={props.user?.first_name}/>
				</Form.Item>
				<Form.Item label={'Фамилия/Отчество'}>
					<Input disabled value={props.user?.last_name}/>
				</Form.Item>
				<Form.Item label={'Адрес'}>
					<Input disabled value={props.user?.address}/>
				</Form.Item>
				<Form.Item label={'Контакт'}>
					<Input disabled value={props.user?.contact}/>
				</Form.Item>
			</Form>
		</Card>
	);
};

export default Index;