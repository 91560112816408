export const PACKAGE_VIEW_BY_TYPE = {
	1: 'Документ',
	2: 'Конверт',
	3: 'Коробка',
	4: 'Пакет',
	5: 'Другое',
}

export const STATUS_TYPE = {
	1: { label: 'Ожидание', color: '#FFA500' }, // Оранжевый
	2: { label: 'Курьер забрал', color: '#FFD700' }, // Золотой
	3: { label: 'На складе', color: '#00BFFF' }, // Голубой
	4: { label: 'В пути', color: '#1E90FF' }, // Ярко-синий
	5: { label: 'В локации получателя', color: '#32CD32' }, // Лаймовый
	6: { label: 'Доставка получателю', color: '#7B68EE' }, // Средний синий
	7: { label: 'Завершено', color: '#228B22' }, // Зеленый
	8: { label: 'Возврат', color: '#FF4500' }, // Оранжево-красный
	9: { label: 'Отмена', color: '#DC143C' } // Малиновый
}

export const PACKAGE_WEIGHT_BY_TYPE = {
	1: 'до 1кг',
	2: 'до 5кг',
	3: 'до 10кг',
	4: 'до 15кг',
	5: 'Другое',
}