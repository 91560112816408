import React from "react";

import { Form, Input } from "antd";
import InputMask from "react-input-mask";
const FormInputComponent = (props) => {
  return (
    <div className="form-group">
      <label>{props.title}</label>

      <Form.Item
        name={props.name}
        rules={[{ required: true, message: props.error_text }]}
      >
          { props.type === 'tel' ? (
              <InputMask
                  disabled={props.disabled}
                  style={props.disabled && {
                      background: 'rgba(0, 0, 0, 0.04)',
                      color: 'rgba(0, 0, 0, 0.25)'
                  }}
                  type={'tel'}
                  mask="+\9\96-999-99-99-99"
                  value={props.value}
              >
                  {(props) => <Input {...props}/>}
              </InputMask>
          ) : (
              <Input
                  type={props.type ? props.type : "text"}
                  disabled={props.disabled}
                  value={props.value}
              />
          )}
      </Form.Item>
    </div>
  );
};

export default FormInputComponent;
