import React from 'react';
import Navigation from "../components/Navigation";
import {Content} from "antd/es/layout/layout";
import {useNavigate} from "react-router";


const PageLayout = (props) => {
	const navigate = useNavigate();

	const logout = () => {
		localStorage.removeItem('ae:a:token');
		localStorage.removeItem('ae:r:token');
		return navigate('/login');
	};

	return (
		<div className="main-container">
			<Content className={'container'}>
				{props.children}
			</Content>
			{props.permitted !== false && (
				<Navigation logout={logout} navigate={navigate}/>
			)}
		</div>
	);
};

export default PageLayout;