import React from "react";
import { Form, Input, Button, Card } from "antd";

const Auth = (props) => {
  const { auth } = props;

  const handleSubmit = (values) => {
    auth(values);
  };

  return (
    <Card className="login-form" style={{minWidth: 320, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'}}>
      <Form onFinish={handleSubmit}>
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Введите имя пользователя" }]}
        >
          <Input placeholder="Имя пользователя" style={{minHeight: 45}}/>
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Введите пароль" }]}
        >
          <Input type="password" placeholder="Пароль" style={{minHeight: 45}}/>
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          block
          style={{minHeight: 45}}
        >
          Войти
        </Button>
      </Form>
    </Card>
  );
};

export default Auth;
