import React from "react";

import { Drawer } from "antd";

const DrawerModal = (props) => {
  return !props.is_off ? null : (
    <Drawer
      open={props.open}
      title={props.title}
      className={props.className}
      onClose={props.onClose}
      autoFocus={false}
      height={520}
      placement="bottom"
    >
      {props.children}
    </Drawer>
  );
};

export default DrawerModal;
