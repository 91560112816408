import React from 'react';
import './MenuItem.css'
import {NavLink} from "react-router-dom";


const MenuItem = (props) => {
	return (
		props.isButton ? props.render() : (
			<NavLink key={props.index} className={`item ${props.isMain ? 'main' : ''} ${props.active ? 'active' : ''}`} to={props.to}>
				{props.icon}
			</NavLink>
		)
	);
};

export default MenuItem;