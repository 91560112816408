import React, { useState } from "react";
import MenuItem from "./MenuItem";
import "./index.css";
import {
  BarChartOutlined,
  HomeOutlined,
  PlusOutlined,
  PoweroffOutlined,
  UserOutlined,
} from "@ant-design/icons";
import CreateUpdateModal from "../Orders/createUpdateModal";

const Index = (props) => {
  const menuItems = [
    {
      isButton: true,
      render: () => (
        <div className={"item"} onClick={props.logout}>
          <PoweroffOutlined className={"item-icon"} />
        </div>
      ),
    },
    {
      isButton: true,
      render: () => (
        <div className="item">
          <BarChartOutlined className={"item-icon"} />
        </div>
      ),
      active: window.location.pathname === "/statistic",
    },
    {
      isMain: true,
      isButton: true,
      render: () => (
        <div className={"item main"} onClick={showCloseModal}>
          <PlusOutlined className={"item-icon"} />
        </div>
      ),
    },
    {
      to: "/profile",
      icon: () => <UserOutlined className={"item-icon"} />,
      active: window.location.pathname === "/profile",
    },
    {
      to: "/",
      icon: () => <HomeOutlined className={"item-icon"} />,
      active: window.location.pathname === "/",
    },
  ];
  // const [showModal, setShowModal] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const showCloseModal = () => setShowModal(!showModal);

  return (
    <div className={"navigation"}>
      <CreateUpdateModal
        navigate={props.navigate}
        show={showModal}
        setShow={setShowModal}
      />
      {menuItems.map((item, index) => (
        <MenuItem {...item} index={index} key={index} />
      ))}
    </div>
  );
};

export default Index;
