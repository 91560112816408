import React, {useEffect, useState} from "react";
import {axiosAPI} from "./axiosAPI";
import {Button, Typography} from "antd";
import {STATUS_TYPE} from "./constants";
import CreateUpdateModal from "./components/Orders/createUpdateModal";
import "./App.css";
import PackageIcon from "./icons/package.png";
import moment from "moment";

const App = (props) => {
	const [orders, setOrders] = useState([]);

	const fetchOrders = async () => {
		try {
			const res = await axiosAPI.get("/orders/");
			setOrders(res.data);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		(async () => await fetchOrders())();
	}, [props]);

	return (
		<div style={{marginBottom: "5vh"}}>
			<Typography.Title className={"title"}>Мои заявки</Typography.Title>
			{orders?.map((order, idx) => (
				<OrderCart order={order} fetchOrders={fetchOrders} key={idx}/>
			))}
			<div style={{height: 40}}/>
		</div>
	);
};

function OrderCart({order, fetchOrders}) {
	const [show, setShow] = useState(false);
	return (
		<div className="card">
			<div className="status-block">
				<div className="status-content">
					<span>{order.from_location.name}</span>
					<span className="separator"></span>
					<span>{order.to_location.name}</span>
					<span className="separator">•</span>
					<span
						className="status-label"
						style={{backgroundColor: STATUS_TYPE[order.status].color}}
					>
            			{STATUS_TYPE[order.status].label}
					</span>
				</div>
				<span>{moment(order.created_date).format("YYYY-MM-DD HH:mm")}</span>
			</div>
			<div className="card-body">
				<div className="icon">
					<img src={PackageIcon} alt="Иконка посылки"/>
				</div>
				<div className="details">
					<h3 className="title">Посылка ID-{order.id}</h3>
					<p className="id"></p>
				</div>
			</div>
			<div>
				<div className="info-field">
					<strong>Адрес отправителя:</strong> {order.from_address}
				</div>
				<div className="info-field">
					<strong>Адрес получателя:</strong> {order.to_address}
				</div>
				{order.status > 1 && (
					<div className="info-field">
						<strong>Цена:</strong> {order.price} сом
					</div>
				)}
			</div>
			<div className="buttons">
				{[2, 6].includes(order.status) && order.courier_contact && (
					<a href={`tel:${order.courier_contact}`}>
						<Button style={{width: "90%"}} className={"tel"}>
							Позвонить курьеру
						</Button>
					</a>
				)}
				<Button type={"primary"} onClick={() => setShow(true)}>
					Изменить
				</Button>

				<CreateUpdateModal
					show={show}
					order={order}
					setShow={setShow}
					fetchOrders={fetchOrders}
				/>
			</div>
		</div>
	);
}

export default App;
