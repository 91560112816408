import React, { useEffect, useState } from "react";
import StandardModal from "./StandardModal";
import DrawerModal from "./DrawerModal";

const ModalDynamic = (props) => {
  const [isMini, setMini] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setMini(window.innerWidth < 576);
    }
  }, []);
  return (
    <>
      <StandardModal {...props} is_off={isMini}>
        {props.children}
      </StandardModal>
      <DrawerModal {...props} is_off={isMini}>
        {props.children}
      </DrawerModal>
    </>
  );
};

export default ModalDynamic;
