import axios from "axios";

export const axiosAPI = axios.create({
	baseURL: process.env.REACT_APP_API_URL
});

axiosAPI.interceptors.request.use(function (config) {
	// Do something before request is sent
	if (config.url.split('/')[0] !== 'users') {
		config.headers['Authorization'] = `Bearer ${localStorage.getItem('ae:a:token')}`
	}
	return config;
}, function (error) {
	// Do something with request error
	return Promise.reject(error);
});


