import React, {Component} from 'react';
import {message} from "antd";
import Auth from "../../components/Auth";
import {axiosAPI} from "../../axiosAPI";


class Index extends Component {
	auth = async (data) => {
		try {
			const res = await axiosAPI.post('/users/auth/token/', data);
			localStorage.setItem('ae:a:token', res.data?.access);
			localStorage.setItem('ae:r:token', res.data?.refresh);
			message.success('Успешный вход в систему!');
			console.log(this.props)
			this.props.navigate('/')
		} catch (e) {
			message.error('Повторите попытку :(');
		}
	};

	render() {
		return (
			<div className="login-page"
				 style={{display: 'flex', alignItems: 'center', height: '100vh', justifyContent: 'center'}}>
				<Auth auth={this.auth}/>
			</div>
		);
	}
}

export default Index;
