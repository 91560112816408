import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {axiosAPI} from "../axiosAPI";
import PageLayout from "../layouts/PageLayout";
import PullToRefresh from 'pulltorefreshjs';


const RouterHoc = (parent_props) => {
	const [user, setUser] = useState(null);
	const navigate = useNavigate();

	const NewRoute = (props) => {
		useEffect(() => {
			PullToRefresh.init({
				mainElement: 'body',
				onRefresh() {
					window.location.reload();
				},
				instructionsPullToRefresh: 'Проведите вниз чтобы обновить данные',
				instructionsReleaseToRefresh: 'Отпустите, чтобы обновить',
				instructionsRefreshing: 'Обновление',
			});
			if (props.permitted !== false && !props.user) {
				const token = localStorage.getItem('ae:a:token');
				const refresh = localStorage.getItem('ae:r:token');
				if (!token || !refresh) {
					localStorage.removeItem('ae:a:token');
					localStorage.removeItem('ae:r:token');
					return navigate('/login');
				} else {
					(async () => {
						try {
							await axiosAPI.post('/users/auth/verify/', {token});
							try {
								const res_profile = await axiosAPI.get('/users/auth/', {
									headers: {
										'Authorization': `Bearer ${token}`
									}
								});
								setUser(res_profile.data);
							} catch (e) {
								console.log(e)
							}
						} catch (e) {
							if (!refresh) {
								localStorage.removeItem('ae:a:token');
								localStorage.removeItem('ae:r:token');
								return navigate('/login');
							} else {
								try {
									const new_access = await axiosAPI.post('/users/auth/refresh/', {refresh});
									localStorage.setItem('ae:a:token', new_access.data?.access);
									return navigate('/');
								} catch (e) {
									localStorage.removeItem('ae:a:token');
									localStorage.removeItem('ae:r:token');
									return navigate('/login');
								}
							}
						}
					})()
				}
			}
		}, [props.permitted, props.user]);
		const Component = props.component;
		return (
			<PageLayout permitted={parent_props.permitted}><Component navigate={navigate} user={user}/></PageLayout>
		)
	}

	return NewRoute(parent_props)
};

export default RouterHoc;