import React, {Component} from "react";
import {axiosAPI} from "../../axiosAPI";
import {Button, Flex, Form, message, Select, Space} from "antd";
import "./createUpdateModal.css";
import TextArea from "antd/es/input/TextArea";
import {PACKAGE_VIEW_BY_TYPE, PACKAGE_WEIGHT_BY_TYPE} from "../../constants";
import ModalDynamic from "../Modal";
import FormInputComponent from "../ui/FormInputComponent";

class CreateUpdateModal extends Component {
	state = {
		locations: [],
		values: {
			from_location_id: null,
			from_address: null,
			from_contact: null,
			to_location_id: null,
			to_address: null,
			to_contact: null,
			view: 1,
			weight: null,
			amount: 1,
			comment: "",
		},

		step: 1,
		loading: false,
	};

	async componentDidMount() {
		await this.fetchLocations();
	}

	onCloseModal = async () => {
		this.props.setShow(false);
		this.setState({...this.state.step, step: 1});
	};

	fetchLocations = async () => {
		try {
			const res = await axiosAPI.get("/orders/locations/");
			this.setState({locations: res.data});
		} catch (e) {
			console.log(e);
		}
	};

	updateOrder = async (values) => {
		this.setState({loading: true});
		try {
			await axiosAPI.patch(`/orders/${this.props.order?.id}/`, values);
			this.onCloseModal();
			await this.props.fetchOrders();
			message.success("Сохранено!");
			this.setState({
				values: {
					from_location_id: null,
					from_address: null,
					from_contact: null,
					to_location_id: null,
					to_address: null,
					to_contact: null,
					view: 1,
					weight: null,
					amount: 1,
					comment: "",
				},

				step: 1,
				loading: false
			});
		} catch (e) {
			console.log(e);
		}
	};

	createOrder = async (values) => {
		this.setState({loading: true});
		try {
			await axiosAPI.post(`/orders/`, values);
			this.props.setShow(false);
			this.props.navigate("/");
			message.success(
				<>
					Вы создали заявку!
					<br/>
					Операторы очень скоро обработают вашу заявку :)
				</>,
				10
			);
			this.setState({
				values: {
					from_location_id: null,
					from_address: null,
					from_contact: null,
					to_location_id: null,
					to_address: null,
					to_contact: null,
					view: 1,
					weight: null,
					amount: 1,
					comment: "",
				},

				step: 1,
				loading: false
			});
		} catch (e) {
			console.log(e);
		}
	};

	onChangeSelect = (key, value) => this.setState({[key]: value});
	onChangeInput = (e) => this.setState({[e.target.name]: e.target.value});

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps !== this.props) {
			this.setState({
				...this.state,
				...this.props.order,
				values: {
					to_location_id: this.props.order?.to_location?.id,
					from_location_id: this.props.order?.from_location?.id,
					from_address: this.props.order?.from_address,
					from_contact: this.props.order?.from_contact,
					to_address: this.props.order?.to_address,
					to_contact: this.props.order?.to_contact,
					view: this.props.order?.view,
					weight: this.props.order?.weight,
					amount: this.props.order?.amount,
					comment: this.props.order?.comment,
				},
			});
		}
	}

	onSubmit = async (values) => {
		switch (this.state.step) {
			case 1:
				this.setState({
					...this.state,
					step: 2,
					values: {
						...this.state.values,
						...values,
						from_contact: values.from_contact.replaceAll('-', '').replaceAll('_', '').replaceAll('+', '')
					},
				});
				break;
			case 2:
				this.setState({
					...this.state,
					step: 3,
					values: {
						...this.state.values,
						...values,
						to_contact: values.to_contact.replaceAll('-', '').replaceAll('_', '').replaceAll('+', '')
					},
				});
				break;
			case 3:
				let data = {...this.state.values, ...values};
				this.setState({
					...this.state,
					values: data,
				});
				this.props.order ? this.updateOrder(data) : this.createOrder(data);

				break;

			default:
				this.setState({
					values: {
						from_location_id: null,
						from_address: null,
						from_contact: null,
						to_location_id: null,
						to_address: null,
						to_contact: null,
						view: 1,
						weight: null,
						amount: 1,
						comment: "",
					},

					step: 1,
				});
				break;
		}
	};

	render() {
		return (
			<ModalDynamic
				open={this.props.show}
				title={"Заявка"}
				className={"create_update_modal"}
				onClose={this.onCloseModal}
			>
				<Form
					onFinish={this.onSubmit}
					initialValues={{
						...this.state.values,
					}}
				>
					<Flex gap="10px" className="modal-steps" justify="center">
						{[1, 2, 3].map((step) => (
							<div
								key={step}
								onClick={() => this.setState({...this.state.step, step})}
								className={this.state.step >= step ? "active" : ""}
							/>
						))}
					</Flex>
					{this.state.step === 1 && (
						<>
							<div className="form-group">
								<label>Откуда</label>
								<Form.Item
									name="from_location_id"
									rules={[{required: true, message: "Выберите место"}]}
								>
									<Select
										value={this.state.values.from_location_id}
										// onChange={(value) =>
										//   this.onChangeSelect("from_location_id", value)
										// }
										disabled={this.props.order?.status > 1}
									>
										{this.state.locations?.map((location) => (
											<Select.Option key={location.id} value={location.id}>
												{location.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</div>

							<FormInputComponent
								disabled={this.props.order?.status > 1}
								title="Адрес отправителя"
								name="from_address"
								error_text="Введите адрес"
								value={this.state.values.from_address}
							/>

							<FormInputComponent
								disabled={this.props.order?.status > 1}
								title="Контакт отправителя"
								type="tel"
								name="from_contact"
								error_text="Введите номер телефона"
								value={this.state.values.from_contact}
							/>
						</>
					)}
					{/* <hr /> */}
					{this.state.step === 2 && (
						<>
							<div className="form-group">
								<label>Куда</label>
								<Form.Item
									name="to_location_id"
									rules={[{required: true, message: "Выберите место"}]}
								>
									<Select
										value={this.state.from_location_id}
										onChange={(value) =>
											this.onChangeSelect("to_location_id", value)
										}
										disabled={this.props.order?.status > 1}
									>
										{this.state.locations?.map((location) => (
											<Select.Option key={location.id} value={location.id}>
												{location.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</div>

							<FormInputComponent
								disabled={this.props.order?.status > 1}
								title="Адрес получателя"
								name="to_address"
								error_text=""
								value={this.state.values.to_address}
							/>

							<FormInputComponent
								disabled={this.props.order?.status > 1}
								type={'tel'}
								title="Контакт получателя"
								name="to_contact"
								error_text="Введите номер телефона"
								value={this.state.values.to_contact}
							/>
						</>
					)}
					{/* <hr /> */}
					{this.state.step === 3 && (
						<>
							<div className="form-group">
								<label>Вид посылки</label>
								<Form.Item
									name="view"
									rules={[{required: true, message: ""}]}
								>
									<Select
										// onChange={(value) => this.onChangeSelect("view", value)}
										// name={"view"}
										value={PACKAGE_VIEW_BY_TYPE[this.state.values.view]}
										disabled={this.props.order?.status > 1}
									>
										{Object.keys(PACKAGE_VIEW_BY_TYPE).map((key) => (
											<Select.Option key={key} value={Number(key)}>
												{PACKAGE_VIEW_BY_TYPE[key]}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</div>

							<div className="form-group">
								<label>Вес (килограмм.грамм)</label>
								<Form.Item
									name="weight"
									rules={[{required: true, message: ""}]}
								>
									<Select
										// onChange={(value) => this.onChangeSelect("weight", value)}
										// name={"weight"}
										value={PACKAGE_WEIGHT_BY_TYPE[this.state.values.weight]}
										disabled={this.props.order?.status > 1}
									>
										{Object.keys(PACKAGE_WEIGHT_BY_TYPE).map(key => (
											<Select.Option key={key} value={Number(key)}>
												{PACKAGE_WEIGHT_BY_TYPE[key]}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</div>

							<FormInputComponent
								disabled={this.props.order?.status > 1}
								title="Количество"
								name="amount"
								type={"number"}
								error_text=""
								value={this.state.values.amount}
							/>

							<div className="form-group">
								<label>
									Комментарии (<b>операторы обязательно прочитают</b>)
								</label>
								<Form.Item
									name="comment"
									rules={[{required: false, message: ""}]}
								>
									<TextArea
										// onChange={this.onChangeInput}
										// name={"comment"}
										disabled={this.props.order?.status > 1}
										value={this.state.values.comment}
									/>
								</Form.Item>
							</div>
						</>
					)}
					<Flex className="footer-buttons">
						<Space style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
							<Button style={{width: '100%', height: '100%'}} onClick={this.onCloseModal}>Закрыть</Button>
							<Button loading={this.state.loading}
									style={{width: '100%', height: '100%'}} type="primary" htmlType="submit">
								{this.state.step === 3 ? "Сохранить" : "Далее"}
							</Button>
						</Space>
					</Flex>
				</Form>
			</ModalDynamic>
		);
	}
}

export default CreateUpdateModal;
