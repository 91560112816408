import React from "react";

import { Modal } from "antd";

const StandardModal = (props) => {
  return props.is_off ? null : (
    <Modal
      open={props.open}
      title={props.title}
      onClose={props.onClose}
      onCancel={props.onClose}
      className={props.className}
      centered={true}
      footer=""
    >
      {props.children}
    </Modal>
  );
};

export default StandardModal;
